import "@fontsource-variable/rethink-sans";

import Paths from "./Routes";
import { BrowserRouter as Router } from "react-router-dom";
import { AviatorErrorBoundary } from "./pages/Error";
import { useFeatureFlag } from "./services/featureFlag";
import { useEffect } from "react";

function App() {
  const redirectToNewAviator = useFeatureFlag().getFlag(
    "redirect-to-new-aviator"
  );

  useEffect(() => {
    const queryString = window.location.search;

    // Save query string to session storage if the url is not a salesforce auth callback
    if (queryString && !queryString?.startsWith("?code=")) {
      sessionStorage.setItem("queryString", queryString);
    }

    if (redirectToNewAviator) {
      const storedQueryString = sessionStorage.getItem("queryString");

      const redirectUrl = new URL(
        process.env.REACT_APP_REDIRECT_URL ||
          "https://dev.agent-quote.goosehead.com"
      );

      if (storedQueryString) redirectUrl.search = storedQueryString;

      window.location.replace(redirectUrl.href);
    }
  }, [redirectToNewAviator]);

  return (
    <Router>
      <AviatorErrorBoundary>
        <Paths />
      </AviatorErrorBoundary>
    </Router>
  );
}

export default App;
